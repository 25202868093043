.DayPicker {
	flex-direction: row;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

.DayPicker-wrapper {
	position: relative;
	flex-direction: row;
	user-select: none;
	width: 100%;
	margin: 0;
	padding: 0;
}

.DayPicker-Caption {
	padding: 0;
}

.DayPicker-NavButton--prev {
	background-image: url('/assets/icons/picker-left.png') !important;
}

.DayPicker-NavButton--next {
	margin-right: -4px;
	background-image: url('/assets/icons/picker-right.png') !important;
}

.DayPicker-Months {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
}

.DayPicker-Weekday {
	color: #657072 !important;
}

.DayPicker-Day--selected {
	background-color: $font-color-hover !important;
}

.DayPicker-Month {
	display: table;
	margin: 0;
	padding: 0;
	margin-top: auto;
	border-spacing: 0;
	border-collapse: collapse;
	user-select: none;
}
