%heading {
	color: $font-color-main;
	font-weight: 500;
	margin-top: 0;
	margin-block-end: 0;
	hyphens: auto;
	word-break: keep-all;
}

%h1 {
	font-size: 42px;
	line-height: 48px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 30px;
		line-height: 36px;
	}
}

%h2 {
	font-size: 32px;
	line-height: 40px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 25px;
		line-height: 32px;
	}
}

%h3 {
	font-size: 26px;
	line-height: 36px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 22px;
		line-height: 28px;
	}
}

%h4 {
	font-size: 22px;
	line-height: 32px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 18px;
		line-height: 24px;
	}
}

%h5 {
	font-size: 18px;
	line-height: 32px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 14px;
		line-height: 24px;
	}
}

%h6 {
	font-size: 15px;
}

%b {
	font-weight: 500;
}

%p {
	font-weight: $global-font-weight;
	font-size: 18px;
	line-height: 32px;
	color: $color-primary-blue;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 14px;
		line-height: 24px;
	}
}

%small {
	font-weight: $global-font-weight;
	font-size: 16px;
	line-height: 24px;
	color: $color-primary-blue;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 12px;
		line-height: 18px;
	}
}

%card {
	font-size: 16px;
	font-style: normal;
	font-weight: 350;
	line-height: 24px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 12px;
		font-style: normal;
		font-weight: 350;
		line-height: 18px;
	}
}

%screenreader {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}

%container {
	width: 100%;
	max-width: $container-width;
	margin: 0 auto;
	padding: 0 16px;

	.container {
		padding: 0;
	}
}
