// Primary
$color-primary-blue: var(--color-primary);
$color-primary-blue-non-variable: #1b143c;
$color-primary-yellow: #d9e020;
$global-primary-blue: $color-primary-blue;

// Secondary
$color-secondary-blue: #005b7f;
$color-secondary-green: #48a842;
$color-secondary-yellow: #ffdd00;
$color-secondary-blue-faded: #9dbacc;

$color-tint-blue-99: #f5fafc;
$color-tertiary-blue: #1c96c4;

// Grey
$color-tint-grey-100: #ffffff;
$color-tint-grey-80: #f3f4f6;
$color-tint-grey-60: #ebebeb;
$color-tint-grey-40: #cfcfcf;
$color-tint-grey-30: #c4c4c4;
$color-tint-grey-20: #acacac;
$color-tint-grey-0: #000000;

$global-border-color: $color-tint-grey-40;

// Webbio components
$color-tint-grey-96: #f5f5f5;
$color-tint-grey-89: #e3e3e3;
$color-tint-grey-68: #aeaeae;
$color-tint-grey-43: #6e6e6e;
$color-tint-grey-25: #747474;
$color-tint-grey-14: #232323;

$color-info-success: #8ce387;
$color-info-warning: #ffbf75;
$color-info-danger: #ff8a8a;
$color-info-danger-faded: lighten($color-info-danger, 20%);
$color-info-danger-darker: darken($color-info-danger, 10%);
$color-info-danger-darkest: darken($color-info-danger, 15%);

$global-border-dark-color: $color-primary-blue-non-variable;
$global-border-style: 1px solid $global-border-color;
$global-border-dark-style: 1px solid $global-border-dark-color;
$global-shadow-style: 0px 3px 0px $global-border-color;

// Typography
$global-font-family: var(--font-family);
$global-font-weight: var(--font-weight);
$font-color-main: $color-primary-blue;
$font-color-body: $color-primary-blue;
$font-color-hover: #0066cc;
$font-color-pressed: #00446e;
$font-color-faded: $color-tint-grey-80;
$font-color-placeholder: black;
$font-color-dark: #222222;

$global-border-radius: 2px;

//
// Breakpoints
//
$container-width: 1200px !default;

$breakpoint-xsmall: 480px !default;
$breakpoint-small: 640px !default;
$breakpoint-small-medium: 768px !default;
$breakpoint-medium: $container-width !default;
$breakpoint-large: $container-width !default;
$breakpoint-xlarge: 1600px !default;

$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-medium-max: ($breakpoint-small-medium - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;

//
// Buttons
//
$button-primary-background: $color-primary-blue;
$button-primary-background-hover: $color-tint-grey-40;
$button-primary-background-pressed: $color-tint-grey-40;
$button-primary-background-active: #0e4f68;
