@import "~styles/includes/index.scss";
/* general datepicker styling */
.react-datepicker {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 160%;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	color: #000;
	position: relative;
	top: -8px;
	right: -6px;
	box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.06);
	text-transform: capitalize;
	padding-bottom: 16px;
}

.react-datepicker-popper {
	z-index: 1;
}

.react-datepicker__current-month {
	margin-bottom: 4px;
}

/* Month and Year dropdown arrow */
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
	position: absolute;
	top: 10px;
	right: 0;
	border-left: 3.5px solid transparent;
	border-right: 3.5px solid transparent;
	border-top: 4px solid #333333;
}

/* header alignment */
.react-datepicker__header {
	text-align: center;
	background: $color-primary-blue;
	color: white;
	padding-top: 16px;
	position: relative;
	border: 0px;
	border-radius: 0px;
	border-bottom: 1px solid #e1e1e1;
	margin-bottom: 16px;
}

.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__year-dropdown-container--scroll {
	display: inline-block;
}

.react-datepicker__month-dropdown-container--scroll {
	margin-right: 16px;
}

.react-datepicker__year-dropdown-container--scroll {
	margin-left: 16px;
}

/* Month navigation arrow styling */
.react-datepicker__navigation {
	line-height: 1.7rem;
	cursor: pointer;
	position: absolute;
	top: 10px;
	z-index: 1;
	text-indent: -999em;
	overflow: hidden;
	border: solid #333333;
	border-width: 0 2px 2px 0;
	padding: 3px;
	max-height: 1px;
	max-width: 1px;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
	top: 22px;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}
.react-datepicker__navigation--next {
	left: auto;
	right: 30px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

/* Year navigation arrow styling */
.react-datepicker__navigation--years {
	position: relative;
}

.react-datepicker__navigation--years-upcoming,
.react-datepicker__navigation--years-previous {
	top: -2px;
	max-width: 0.01px;
	max-height: 0.01px;
	border-bottom-color: #333333;
	border: solid #333333;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
.react-datepicker__navigation--years-upcoming {
	top: 2px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}

/* Time input styling */
.react-datepicker__input-time-container {
	width: 100%;
	display: flex;
	padding: 12px 0 8px 20px;
	.react-datepicker-time__caption {
		font-weight: normal;
		font-size: 14px;
		line-height: 160%;
		padding-top: 8px;
	}
	.react-datepicker-time__input-container {
		.react-datepicker-time__input {
			margin-left: 8px;
			height: 40px;
			input {
				box-sizing: border-box;
				border-radius: 3px;
				color: #9d9d9d;
				padding-left: 8px;
				padding-right: 5px;
				font-size: 15px;
				background: #ffffff;
				box-shadow: none;
				border: 1px solid #9fa9ad;
				margin: 0;
			}
			input[type='time'] {
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				&::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				-moz-appearance: textfield;
			}
		}
		.react-datepicker-time__delimiter {
			margin-left: 5px;
			display: inline-block;
		}
	}
}

/* Day name styling */
.react-datepicker__day-name {
	font-weight: normal;
	font-size: 12px;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	margin: 0 4px;
	color: #c1c1c1;
}

/* Day number styling */
.react-datepicker__day {
	color: #6d6d6d;
	font-weight: normal;
	font-size: 12px;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0 4px;
	cursor: pointer;
	&:hover {
		border-radius: 1rem;
		background-color: #f0f0f0;
	}
}
.react-datepicker__week {
	white-space: nowrap;
	padding: 0 10px;
}

.react-datepicker__day--today {
	font-weight: bold;
}

/* Selected day color style */
.react-datepicker__day--selected {
	font-weight: normal;
	color: #fff;
	border-radius: 1rem;
	background-color: #c55b03;
	&:hover {
		background-color: #c55b03;
		border-radius: 1rem;
	}
}

/* Keyboard selection styling */
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	border-radius: 1rem;
	background-color: $color-primary-blue;
	color: white;
	&:hover {
		background-color: #f0f0f0;
	}
}

/* Month and Year dropdown styling */
.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
	font-weight: normal;
	font-size: 14px;
	position: relative;
	&:hover {
		cursor: pointer;
	}
	&--selected-month,
	&--selected-year {
		padding-right: 16px;
	}
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
	background-color: #ffffff;
	padding: 4px;
	position: absolute;
	width: 50%;
	left: 25%;
	z-index: 1;
	border-radius: 0.3rem;
	border: 1px solid #e1e1e1;
	&:hover {
		cursor: pointer;
	}
}

.react-datepicker__month-option,
.react-datepicker__year-option {
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
	color: #6d6d6d;

	&:hover {
		background-color: #f9f9f9;
	}
}

/* Hide obsolete days outside-month */
.react-datepicker__day--outside-month {
	color: transparent;
	pointer-events: none;
	background-color: #ffffff;
}
