.rsexpanded .rsbtn_left,
.rsbtn_right,
#rsbtn_scrollcontrols,
.rsbtn_tooltoggle,
a.rsbtn,
.rsbtn.rsexpanded .rsbtn_play {
	display: none !important;
}

.rsbtn_left {
	margin-left: 0 !important;
}

.rs_addtools,
.rsbtn_text {
	&:before,
	&:after {
		margin-top: -1px !important;
	}
	span {
		font-family: $global-font-family !important;
		font-weight: $global-font-weight !important;
		font-size: 16px !important;

		@media (max-width: $breakpoint-xsmall-max) {
			display: none !important;
		}
	}
}

.rs_addtools {
	.rsbtn {
		margin-bottom: 0 !important;
	}

	.rsbtn_play,
	.rsbtn_play:hover {
		background: transparent !important;
	}

	a,
	span {
		color: white !important;
	}
}

.rsbtn {
	margin-bottom: 0 !important;

	.rsbtn_play:focus,
	.rsbtn_play:focus-visible {
		border: 2px solid $color-primary-blue;
		outline: 2px solid white;
	}

	.rsbtn_play,
	.rsbtn .rsbtn_play {
		border: none !important;
		@media (max-width: $breakpoint-xsmall-max) {
			width: 20px;
		}
	}

	.rsbtn_play:hover,
	.rsbtn_play,
	.rsbtn_exp .rsbtn_exp_inner {
		background: transparent !important;
	}

	.rsbtn_exp,
	.rsbtn_exp_inner {
		.rsbtn_player_item {
			border-left: 1px solid white !important;
			background: $color-primary-blue-non-variable !important;
		}

		.rsbtn_dropdown {
			background: $color-tint-grey-40 !important;
			.rs_slider.vertical::-webkit-slider-thumb {
				-webkit-appearance: none !important;
				appearance: none !important;
				background: #0066cc !important;
			}
			.rs_slider.vertical::-moz-range-thumb {
				background: #0066cc !important;
			}
		}

		.rsbtn_pause:active,
		.rsbtn_pause:focus,
		.rsbtn_pause:hover,
		.rsbtn_stop:active,
		.rsbtn_stop:focus,
		.rsbtn_stop:hover,
		.rsbtn_volume:active,
		.rsbtn_volume:focus,
		.rsbtn_volume:hover,
		.rsbtn_closer:active,
		.rsbtn_closer:focus,
		.rsbtn_closer:hover {
			background: white !important;

			.rsicn:before {
				color: $color-primary-blue !important;
			}
		}
	}
	.rs-player-loadindicator {
		background: white !important;

		.rsbtn-progress {
			background: #0066cc !important;
		}
	}

	a,
	span {
		color: white !important;
	}
}
