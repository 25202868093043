.CookieDeclaration {
	max-width: 740px !important;
}

body #CybotCookiebotDialog {
	border: 2px solid #cfcfcf;
	border-radius: 0;
	box-shadow: 0px 3px 0px #cfcfcf;

	@media (max-width: 914px) {
		max-width: none !important;
		transform: none !important;
		margin: 8vh 16px 0 !important;
		overflow: auto;
		height: 84vh !important;
	}

	@media (min-width: 915px) {
		max-width: 900px !important;
		transform: translateX(-50%);
	}

	#CybotCookiebotDialogBodyContent {
		padding: 40px 70px 24px 40px !important;

		@media (max-width: 914px) {
			padding: 24px 16px !important;
		}

		#CybotCookiebotDialogBodyContentTitle,
		#CybotCookiebotDialogBodyContentText {
			color: $color-primary-blue;
			font-family: $global-font-family;
		}

		#CybotCookiebotDialogBodyContentTitle {
			font-size: 26px;
			line-height: 36px;
			font-weight: 400;
		}

		#CybotCookiebotDialogBodyContentText {
			margin-bottom: 0;
			font-size: 16px;
			line-height: 24px;
			font-weight: 300;
		}
	}

	#CybotCookiebotDialogBodyLevelWrapper {
		#CybotCookiebotDialogBodyLevelButtons {
			margin: 0 !important;
			float: none !important;
			padding: 0 40px 40px;

			@media (max-width: 914px) {
				padding: 0 16px 16px;
			}

			#CybotCookiebotDialogBodyLevelButtonsTable {
				display: block;
				width: auto !important;
				margin: 0;
			}

			#CybotCookiebotDialogBodyLevelButtonsRow {
				display: flex;
				gap: 20px;

				@media (max-width: 914px) {
					flex-direction: column;
				}
			}

			#CybotCookiebotDialogBodyLevelButtonsSelectPane,
			#CybotCookiebotDialogBodyLevelDetailsWrapper {
				display: block;
				padding: 0;
				border: none;
				background-color: transparent;
				float: none;
				height: auto;

				&,
				label,
				#CybotCookiebotDialogBodyLevelDetailsButton {
					font-size: 18px;
					line-height: 32px;
					font-family: $global-font-family;
					font-weight: 300;
					background-position: right 12px;
				}

				#CybotCookiebotDialogBodyLevelDetailsButton {
					color: $font-color-hover;
				}

				input.CybotCookiebotDialogBodyLevelButton + label {
					padding-left: 25px;
					background-image: url('/assets/icons/checkbox.svg');
					background-position: left 8px;
				}

				input[type='checkbox'].CybotCookiebotDialogBodyLevelButton:checked + label {
					background-image: url('/assets/icons/checkbox-checked.svg');
				}

				input.CybotCookiebotDialogBodyLevelButton:disabled + label {
					opacity: 0.6;
				}
			}

			#CybotCookiebotDialogBodyLevelDetailsWrapper {
				@media (max-width: 914px) {
					padding: 16px 0;
					text-align: center;
				}
			}

			#CybotCookiebotDialogBodyLevelButtonsSelectPane {
				display: flex;
				flex: 1 1 auto;
				gap: 14px;
				padding: 0;
				border: 0;

				@media (max-width: 914px) {
					position: relative;
					flex-direction: column;
					margin-top: 8px;
					padding-top: 16px;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: -32px;
						right: -32px;
						border-top: 2px solid $color-tint-grey-30;
					}
				}

				> * {
					flex: 1 1 auto;
					margin-right: 0;
				}
			}
		}

		#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
			display: flex !important;
			width: auto;
			float: none;
			padding: 0 40px 32px;
			gap: 25px;

			@media (max-width: 914px) {
				flex-direction: column;
				gap: 15px;
				padding: 0 16px 16px;
			}

			a.CybotCookiebotDialogBodyButton {
				margin: 0;
				padding: 16px 32px;
				width: auto !important;
				font-family: $global-font-family;
				font-size: 18px;
				line-height: 16px;
				font-weight: 400;
			}

			#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
				background: $color-primary-blue;
				border-color: $color-primary-blue;
				box-shadow: 3px 3px 0px $color-tint-grey-30;

				&:hover {
					background: $color-tint-grey-60;
					border-color: $color-tint-grey-60;
					color: $color-primary-blue;
				}
			}

			#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
				background: white;
				border: 2px solid $color-primary-blue;
				box-shadow: 3px 3px 0px $color-tint-grey-30;
				color: $color-primary-blue;

				&:hover {
					border-color: $color-tint-grey-30;
				}
			}
		}
	}

	#CybotCookiebotDialogDetailBody {
		max-width: none;
		padding: 0 40px 24px;

		@media (max-width: 914px) {
			padding: 0 16px 18px !important;
			max-width: 100%;
			width: calc(100vw - 64px);
		}
	}
}
