html,
body {
	> div[id='__next'] {
		min-height: 100%;
		min-width: 100%;
	}
	font-size: 15px;
}

html,
body,
#__next {
	height: 100%;
	width: 100%;
	font-size: 15px;
}

* {
	box-sizing: border-box;
}

html {
	background-color: $color-tint-grey-96;
	scroll-behavior: smooth;
}

img,
figure {
	max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@extend %heading;
}

h1 {
	@extend %h1;
}

h2 {
	@extend %h2;
}

h3 {
	@extend %h3;
}

h4 {
	@extend %h4;
}

h5 {
	@extend %h5;
}

h6 {
	@extend %h6;
}

a {
	font-size: 14px;
	line-height: 16px;
	color: $color-primary-blue;
	text-decoration: none;

	&:hover {
		color: $font-color-hover;
	}

	&:focus-visible {
		outline-offset: 2px;
	}
}

b,
strong {
	@extend %b;
}

p {
	@extend %p;

	> a {
		font-size: inherit;
		line-height: inherit;
		text-decoration: underline;
	}
}

li {
	font-size: 18px;
	color: $color-primary-blue;
}

small {
	@extend %small;
}

figure {
	margin: 0;

	table {
		width: 100%;
	}
}

.container {
	@extend %container;
}

.isHomepageHeader {
	max-width: 1272px;
}

@font-face {
	font-family: 'Fedra Serif A Pro';
	src: url('../public/assets/fonts/FedraSerifAPro-Medium.eot');
	src: url('../public/assets/fonts/FedraSerifAPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../public/assets/fonts/FedraSerifAPro-Medium.woff2') format('woff2'),
		url('../public/assets/fonts/FedraSerifAPro-Medium.woff') format('woff'),
		url('../public/assets/fonts/FedraSerifAPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Serif A Pro';
	src: url('../public/assets/fonts/FedraSerifAPro-Book.eot');
	src: url('../public/assets/fonts/FedraSerifAPro-Book.eot?#iefix') format('embedded-opentype'),
		url('../public/assets/fonts/FedraSerifAPro-Book.woff2') format('woff2'),
		url('../public/assets/fonts/FedraSerifAPro-Book.woff') format('woff'),
		url('../public/assets/fonts/FedraSerifAPro-Book.ttf') format('truetype');
	font-weight: $global-font-weight;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Serif A Pro';
	src: url('../public/assets/fonts/FedraSerifAPro-MediumItalic.eot');
	src: url('../public/assets/fonts/FedraSerifAPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../public/assets/fonts/FedraSerifAPro-MediumItalic.woff2') format('woff2'),
		url('../public/assets/fonts/FedraSerifAPro-MediumItalic.woff') format('woff'),
		url('../public/assets/fonts/FedraSerifAPro-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Serif LCG';
	src: url('../public/assets/fonts/FedraSerifLCG-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Serif LCG';
	src: url('../public/assets/fonts/FedraSerifLCG-Book.woff2') format('woff2');
	font-weight: $global-font-weight;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Serif LCG';
	src: url('../public/assets/fonts/FedraSerifLCG-MediumItalic.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: 'https://fonts.googleapis.com/css?family=Open+Sans';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: 'https://fonts.googleapis.com/css?family=Open+Sans';
	font-weight: $global-font-weight;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: 'https://fonts.googleapis.com/css?family=Open+Sans';
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
